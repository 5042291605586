import { AlertTypeColors } from "@app/shared/types/alert";
import { StepStatus } from "@app/shared/components/steps-validation/steps-validation.component";

export interface GroupPayment {
  groupPaymentId: number;
  compensationEndDate: string;
  compensationStartDate: string;
  totalLigne: number;
  totalAmount: number;
  statusGroupPayment: StatusGroupPayment;
  createdDate: string;
  creationAuthor: string;
  modifiedDate: string;
  modificationAuthor: string;
  typeGroupPayment: TypeGroupPayment;
  labelTypeGroupPayment: string;
  generationSepaDate: string;
  validationDate: string;
  payedDate: string;
}

export interface GroupPaymentDetail {
  groupPayment: GroupPayment;
  linesPayment: LinePayment[];
  totalPages: number;
  pageNumber: number;
  totalElements: number;
}

export interface LinePayment {
  linePaymentId: number;
  statusLinePayment: string;
  financingContractId: number;
  contractReference: string;
  indemnisationId: number;
  claimId: number;
  compensatedAmount: number;
  validationDate: Date;
  bankCode: string;
  bankName: string;
  bankRib: string;
  sirenNumber: string;
  companyName: string;
  atriumUsage: string;
  disbursementId: number;
}

// eslint-disable-next-line no-shadow
export enum StatusGroupPayment {
  VALD = 'VALD',
  PAYE = 'PAYE',
  GENR = 'GENR',
  VAECO = 'VAECO'
}


// eslint-disable-next-line no-shadow
export enum TypeGroupPayment {
  INDE = 'INDE',
  RBT = 'RBT'
}

export const getGroupPaymentSatusLabel = (groupPayment: GroupPayment): StepStatus => {
  switch (groupPayment?.statusGroupPayment) {
    case StatusGroupPayment.GENR:
      return { statusColor: AlertTypeColors.ORANGE, statusLable: "grouppayment.status.generated" };
    case StatusGroupPayment.VAECO:
      return { statusColor: AlertTypeColors.ORANGE, statusLable: "grouppayment.status.validation-in-progress" };
    case StatusGroupPayment.VALD:
      return { statusColor: AlertTypeColors.GREEN, statusLable: "grouppayment.status.validaded" };
    case StatusGroupPayment.PAYE:
      return {
        statusColor: AlertTypeColors.GREEN,
        statusLable: groupPayment.typeGroupPayment === TypeGroupPayment.INDE ?
          "grouppayment.status.payed" : "grouppayment.status.refund",
      };
  }
  return { statusColor: AlertTypeColors.DEFAULT, statusLable: "" };
};

export const TypeGroupPaymentTranslation: { [type in TypeGroupPayment]: string } = {
  [TypeGroupPayment.INDE]: "grouppayment.status.payed",
  [TypeGroupPayment.RBT]: "grouppayment.status.refund"
}
