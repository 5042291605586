import { Component } from '@angular/core';
import { ModalBody, Modal } from "@app/shared/components/modals/types/modal.model";

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: [
    './confirm-modal.component.scss',
    '../../modal-common-css/modal-common-css.component.scss'
  ]
})
export class ConfirmModalComponent extends Modal {
  confirmModalInfo: ModalBody = {
    modalTitle: '',
    modalMessages: [],
    validation:'',
    cancellation:''
  };

  onInjectInputs(inputs: ModalBody): void {
    if (inputs) {
      this.confirmModalInfo = inputs;
    }
  }
}
