import { Component } from '@angular/core';
import {ModalBody, Modal} from "@app/shared/components/modals/types/modal.model";

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: [
    './information-modal.component.scss',
    '../../modal-common-css/modal-common-css.component.scss'
  ]
})
export class InformationModalComponent extends Modal {
  confirmModalInfo: ModalBody = {
    modalTitle: '',
    validation:''
  };

  onInjectInputs(inputs: ModalBody): void {
    if (inputs) {
      this.confirmModalInfo = inputs;
    }
  }
}
