<div class="modal-dialog">
  <div class="modal-wrapper">
    <div class="modal-content">
      <div class="modal-graphics">
        <img alt="modal-graphics" src="assets/images/feedback-confirmation-modal.svg">
      </div>
      <h3>
        {{ confirmModalInfo.modalTitle }}
      </h3>
      <div class="button-block">
        <button bpi-button appearance="primary" (click)="close(true)">
          {{ confirmModalInfo.validation }}
        </button>
      </div>
    </div>
  </div>
</div>
