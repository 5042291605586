import { Observable, Subject } from "rxjs";
import { ComponentRef } from "@angular/core";
import { ModalFactory } from "@app/shared/components/modals/factory/modal-factory.component";


export abstract class Modal {
  modalInstance!: ModalRef;

  abstract onInjectInputs(inputs: ModalBody): void;

  close(output?: boolean): void {
    this.modalInstance.close(output);
  }

  dismiss(output?: unknown): void {
    this.modalInstance.dismiss(output);
  }
}

export class ModalRef {
  private result: Subject<boolean> = new Subject<boolean>();

  constructor(
    private modalContainer: ComponentRef<ModalFactory>,
    private modal: ComponentRef<Modal>,
  ) {
    this.modal.instance.modalInstance = this;
  }

  close(output?: boolean) {
    this.result.next(output);
    this.destroy();
  }

  dismiss(output: unknown) {
    this.result.error(output);
    this.destroy();
  }

  onResult(): Observable<boolean> {
    return this.result.asObservable();
  }

  private destroy() {
    this.modal.destroy();
    this.modalContainer.destroy();
    this.result.complete();
  }
}

export type ModalBody = {
  modalTitle: string;
  validation: string;
  modalMessages?: Message[];
  cancellation?: string;
};

interface Message {
  title: string;
  message: string | number;
}
