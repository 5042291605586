// eslint-disable-next-line no-shadow
export enum ContractStatus {
  // NOTF = 'NOTF', // Notifié
  // VIVT = 'VIVT', // Vivant
  TERM = 'TERM', // Terminé
  DECH = 'DECH', // Déchu
  ANN = 'ANN', // Annulé
  VALD = 'VALD', // Validé,
  CTX = 'CTX', // Contentieux
  ECHU = 'ECHU', // Echu
  INDP = 'INDP', // Indemnisation partielle
  INDT = 'INDT' // Indemnisation totale

}

// eslint-disable-next-line no-shadow
export const enum StatusDotColors {
  // NOTF = '#5273A7',
  // VIVT = '#00B785',
  TERM = '#5273A7',
  DECH = '#BE0E25',
  ANN = '#ADAAA6',
  VALD = '#00B785',
  CTX = '#BE0E25',
  ECHU = '#5273A7',
  INDP = '#ffcd00',
  INDT = '#ADAAA6'
}

const contractStatusLabel = 'contracts.contract-status';

export interface ContractStatusDot {
  label: string;
  color: StatusDotColors;
}

export const CONTRACT_STATUS_DOT: {
  [type in ContractStatus]: ContractStatusDot;
} = {
  [ContractStatus.TERM]: {
    label: `${contractStatusLabel}.${ContractStatus.TERM}`,
    color: StatusDotColors.TERM,
  },
  [ContractStatus.DECH]: {
    label: `${contractStatusLabel}.${ContractStatus.DECH}`,
    color: StatusDotColors.DECH,
  },
  [ContractStatus.ANN]: {
    label: `${contractStatusLabel}.${ContractStatus.ANN}`,
    color: StatusDotColors.ANN,
  },
  [ContractStatus.VALD]: {
    label: `${contractStatusLabel}.${ContractStatus.VALD}`,
    color: StatusDotColors.VALD,
  },
  [ContractStatus.CTX]: {
    label: `${contractStatusLabel}.${ContractStatus.CTX}`,
    color: StatusDotColors.CTX,
  },
  [ContractStatus.ECHU]: {
    label: `${contractStatusLabel}.${ContractStatus.ECHU}`,
    color: StatusDotColors.ECHU,
  },
  [ContractStatus.INDP]: {
    label: `${contractStatusLabel}.${ContractStatus.INDP}`,
    color: StatusDotColors.INDP,
  },
  [ContractStatus.INDT]: {
    label: `${contractStatusLabel}.${ContractStatus.INDT}`,
    color: StatusDotColors.INDT,
  },
};
