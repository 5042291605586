import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, Injectable, Type } from '@angular/core';
import { ModalFactory } from "@app/shared/components/modals/factory/modal-factory.component";
import {Modal, ModalBody, ModalRef} from "@app/shared/components/modals/types/modal.model";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalContainer!: HTMLElement;
  private modalContainerFactory!: ComponentFactory<ModalFactory>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef
  ) {
    this.setupModalContainerFactory();
  }

  open<T extends Modal>(component: Type<T>, inputs?: ModalBody) {
    this.setupModalContainer();
    const modalContainerRef = this.appRef.bootstrap(this.modalContainerFactory);
    const modalComponentRef = modalContainerRef.instance.createModal(component);
    if (inputs) {
      modalComponentRef.instance.onInjectInputs(inputs);
    }
    return new ModalRef(modalContainerRef, modalComponentRef);
  }

  private setupModalContainer() {
    this.modalContainer = document.createElement('app-modal');
    document.getElementsByTagName('main')[0].appendChild(this.modalContainer);
  }

  private setupModalContainerFactory() {
    this.modalContainerFactory = this.componentFactoryResolver.resolveComponentFactory(ModalFactory);
  }
}
