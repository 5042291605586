// eslint-disable-next-line no-shadow
export enum FieldsAttachmentCode {
  ADDITIONALITY_CRITERION_PROOF = 'ADDITIONALITY_CRITERION_PROOF',
  AMORTIZATION_TABLE = 'AMORTIZATION_TABLE',
  BACKUP_PLAN = 'BACKUP_PLAN',
  CERTIFIED_ACCOUNTANT_CERTIFICATE = 'CERTIFIED_ACCOUNTANT_CERTIFICATE',
  COLLECTION_COST_PROOF = 'COLLECTION_COST_PROOF',
  DEBT_COUNTDOWN = 'DEBT_COUNTDOWN',
  DEBT_DECLARATION = 'DEBT_DECLARATION',
  ENDORSEMENT = 'ENDORSEMENT',
  FORMAL_NOTICE_LETTER_BANK_TERM_FORFEITURE = 'FORMAL_NOTICE_LETTER_BANK_TERM_FORFEITURE',
  LEGAL_ACTS = 'LEGAL_ACTS',
  LEGAL_NOTICE_EXTRACT = 'LEGAL_NOTICE_EXTRACT',
  LOAN_AGREEMENT = 'LOAN_AGREEMENT',
  LOAN_END_CERTIFICATE = 'LOAN_END_CERTIFICATE',
  NONASSIGNMENT_DEBT_CERTIFICATE = 'NONASSIGNMENT_DEBT_CERTIFICATE',
  PARTIAL_ASSIGNMENT = 'PARTIAL_ASSIGNMENT',
  PROTOCOL = 'PROTOCOL',
  RECOVERY_PLAN = 'RECOVERY_PLAN',
  STATUTORY_AUDITOR_CERTIFICATE = 'STATUTORY_AUDITOR_CERTIFICATE',
  TAX_RETURN = 'TAX_RETURN',
  TOTAL_ASSIGNMENT = 'TOTAL_ASSIGNMENT',
  TREASURY_DECISION = 'TREASURY_DECISION',
  UNCOLLECTIBILITY_CERTIFICATE = 'UNCOLLECTIBILITY_CERTIFICATE',
}

// eslint-disable-next-line no-shadow
export enum GedAttachmentCodes {
  CONTRPRET = 'CONTRPRET',
  TABLEAMOR = 'TABLEAMOR',
  LIASSE = 'LIASSE',
  RAPACTCAC = 'RAPACTCAC',
  ATTEXPCPT = 'ATTEXPCPT',
  JUSCRIADD = 'JUSCRIADD',
  ATTNCSCRE = 'ATTNCSCRE',
  ACTESJURI = 'ACTESJURI',
  DCPTDECRE = 'DCPTDECRE',
  JUSFRSREC = 'JUSFRSREC',
  LMDDEDUTE = 'LMDDEDUTE',
  EXTANNLEG = 'EXTANNLEG',
  DECLDECRE = 'DECLDECRE',
  ATTFINPRT = 'ATTFINPRT',
  CESSITOTA = 'CESSITOTA',
  CESSIPART = 'CESSIPART',
  CERTIRREC = 'CERTIRREC',
  AVENANT = 'AVENANT',
  PROTOCOLE = 'PROTOCOLE',
  PLNDESAUV = 'PLNDESAUV',
  PLNDEREDR = 'PLNDEREDR',
  DECISTRES = 'DECISTRES',
}

export const Test: { [key: string]: FieldsAttachmentCode } = {
  JUSCRIADD: FieldsAttachmentCode.ADDITIONALITY_CRITERION_PROOF,
  ATTNCSCRE: FieldsAttachmentCode.NONASSIGNMENT_DEBT_CERTIFICATE,
};

export const GedToFieldAttachmentCodeMap: {
  [code in GedAttachmentCodes]: FieldsAttachmentCode;
} = {
  [GedAttachmentCodes.CONTRPRET]: FieldsAttachmentCode.LOAN_AGREEMENT,
  [GedAttachmentCodes.TABLEAMOR]: FieldsAttachmentCode.AMORTIZATION_TABLE,
  [GedAttachmentCodes.LIASSE]: FieldsAttachmentCode.TAX_RETURN,
  [GedAttachmentCodes.RAPACTCAC]:
    FieldsAttachmentCode.STATUTORY_AUDITOR_CERTIFICATE,
  [GedAttachmentCodes.ATTEXPCPT]:
    FieldsAttachmentCode.CERTIFIED_ACCOUNTANT_CERTIFICATE,
  [GedAttachmentCodes.JUSCRIADD]:
    FieldsAttachmentCode.ADDITIONALITY_CRITERION_PROOF,
  [GedAttachmentCodes.ATTNCSCRE]:
    FieldsAttachmentCode.NONASSIGNMENT_DEBT_CERTIFICATE,
  [GedAttachmentCodes.ACTESJURI]: FieldsAttachmentCode.LEGAL_ACTS,
  [GedAttachmentCodes.DCPTDECRE]: FieldsAttachmentCode.DEBT_COUNTDOWN,
  [GedAttachmentCodes.JUSFRSREC]: FieldsAttachmentCode.COLLECTION_COST_PROOF,
  [GedAttachmentCodes.LMDDEDUTE]:
    FieldsAttachmentCode.FORMAL_NOTICE_LETTER_BANK_TERM_FORFEITURE,
  [GedAttachmentCodes.EXTANNLEG]: FieldsAttachmentCode.LEGAL_NOTICE_EXTRACT,
  [GedAttachmentCodes.DECLDECRE]: FieldsAttachmentCode.DEBT_DECLARATION,
  [GedAttachmentCodes.ATTFINPRT]: FieldsAttachmentCode.LOAN_END_CERTIFICATE,
  [GedAttachmentCodes.CESSITOTA]: FieldsAttachmentCode.TOTAL_ASSIGNMENT,
  [GedAttachmentCodes.CESSIPART]: FieldsAttachmentCode.PARTIAL_ASSIGNMENT,
  [GedAttachmentCodes.CERTIRREC]:
    FieldsAttachmentCode.UNCOLLECTIBILITY_CERTIFICATE,
  [GedAttachmentCodes.AVENANT]: FieldsAttachmentCode.ENDORSEMENT,
  [GedAttachmentCodes.PROTOCOLE]: FieldsAttachmentCode.PROTOCOL,
  [GedAttachmentCodes.PLNDESAUV]: FieldsAttachmentCode.BACKUP_PLAN,
  [GedAttachmentCodes.PLNDEREDR]: FieldsAttachmentCode.RECOVERY_PLAN,
  [GedAttachmentCodes.DECISTRES]: FieldsAttachmentCode.TREASURY_DECISION,
};

// eslint-disable-next-line no-shadow
export enum FieldsValueCode {
  AMORTIZATION_MODE = 'AMORTIZATION_MODE',
  AMOUNT_ACCESS = 'AMOUNT_ACCESS',
  AMOUNT_INTEREST = 'AMOUNT_INTEREST',
  AMOUNT_PAYMENT = 'AMOUNT_PAYMENT',
  AMOUNT_RECOVERY = 'AMOUNT_RECOVERY',
  AMOUT_PROVISIONAL_PAYMENT = 'AMOUT_PROVISIONAL_PAYMENT',
  BALANCE_SHEET = 'BALANCE_SHEET',
  BANK_CODE = 'BANK_CODE',
  BANK_COMPENSABLE_AMOUNT = 'BANK_COMPENSABLE_AMOUNT',
  BANK_COMPENSATED_AMOUNT = 'BANK_COMPENSATED_AMOUNT',
  BANK_DESK = 'BANK_DESK',
  BANK_NAME = 'BANK_NAME',
  BORROWER_ADDRESS_FULL = 'BORROWER_ADDRESS_FULL',
  BORROWER_ADDRESS1 = 'BORROWER_ADDRESS1',
  BORROWER_ADDRESS2 = 'BORROWER_ADDRESS2',
  BORROWER_ADDRESS3 = 'BORROWER_ADDRESS3',
  BORROWER_CITY = 'BORROWER_CITY',
  BORROWER_FIRST_NAME = 'BORROWER_FIRST_NAME',
  BORROWER_LAST_NAME = 'BORROWER_LAST_NAME',
  BORROWER_POST_CODE = 'BORROWER_POST_CODE',
  CHECKED_CONTRACT_IND = 'CHECKED_CONTRACT_IND',
  CLAIM_STATUS = 'CLAIM_STATUS',
  COMPANY_NAME = 'COMPANY_NAME',
  WARRANTY_CONTRACT_ID = 'WARRANTY_CONTRACT_ID',
  FINANCING_CONTRACT_ID = 'FINANCING_CONTRACT_ID',
  CONTRACT_STATUS = 'CONTRACT_STATUS',
  CREDIT_EVENT_CODE = 'CREDIT_EVENT_CODE',
  CREDIT_EVENT_DATE = 'CREDIT_EVENT_DATE',
  CURRENT_RISK = 'CURRENT_RISK',
  EMPLOYEES_NUMBER = 'EMPLOYEES_NUMBER',
  COMPANY_EMPLOYEES_NUMBER = 'COMPANY_EMPLOYEES_NUMBER',
  WORKFORCE = 'WORKFORCE',
  WORKFORCE_REQUESTED = 'WORKFORCE_REQUESTED',
  FEES_RATE = 'FEES_RATE',
  FINAL_AMOUNT_PAYMENT = 'FINAL_AMOUNT_PAYMENT',
  FINAL_PAYMENT = 'FINAL_PAYMENT',
  FREQUENCY = 'FREQUENCY',
  CLAIM_ID = 'CLAIM_ID',
  FEES_RATE_AMOUNT = 'FEES_RATE_AMOUNT',
  INNOVATIVE_COMPANY_IND = 'INNOVATIVE_COMPANY_IND',
  LEGAL_STATUS_CODE = 'LEGAL_STATUS_CODE',
  LEGAL_STATUS_TITLE = 'LEGAL_STATUS_TITLE',
  LOAN_AMOUNT = 'LOAN_AMOUNT',
  LOAN_DURATION = 'LOAN_DURATION',
  LOAN_END_DATE = 'LOAN_END_DATE',
  CONTRACT_REFERENCE = 'LOAN_REFERENCE',
  LOAN_RELEASING_DATE = 'LOAN_RELEASING_DATE',
  NAF_CODE = 'NAF_CODE',
  OUTSTANDING_CAPITAL = 'OUTSTANDING_CAPITAL',
  PARTNER_CUSTOMER_NUMBER = 'PARTNER_CUSTOMER_NUMBER',
  PAYMENT_AMOUNT = 'PAYMENT_AMOUNT',
  PAYMENT_DATE = 'PAYMENT_DATE',
  PORTION = 'PORTION',
  PROVISIONAL_PAYMENT_NUMBER_LIST = 'PROVISIONAL_PAYMENT_NUMBER_LIST',
  PROVISIONAL_PAYMENTS = 'PROVISIONAL_PAYMENTS',
  REVENUES = 'REVENUES',
  COMPANY_REVENUE = 'COMPANY_REVENUE',
  SALARY_MASS = 'SALARY_MASS',
  PAYROLL = 'PAYROLL',
  SAMPLE_CONTRACT_IND = 'SAMPLE_CONTRACT_IND',
  SIREN_NUMBER = 'SIREN_NUMBER',
  TOTAL_PGE_AMOUNT = 'TOTAL_PGE_AMOUNT',
  TYPE_PAYMENT = 'TYPE_PAYMENT',
  USER_ASSIGNED = 'USER_ASSIGNED',
  FEES_RATE_1_24 = 'FEES_RATE_1_24',
  FEES_RATE_25_60 = 'FEES_RATE_25_60',
  LAWYER_FIRST_NAME = 'LAWYER_FIRST_NAME',
  LAWYER_LAST_NAME = 'LAWYER_LAST_NAME',
  LAWYER_EMAIL = 'LAWYER_EMAIL',
  CERTIFICATE_DATE = 'CERTIFICATE_DATE',
  COMPENSABLE_AMOUNT_DATE = 'COMPENSABLE_AMOUNT_DATE',
  INSUFFICIENT_ASSETS_CLOSING_DATE = 'INSUFFICIENT_ASSETS_CLOSING_DATE',
  REQUEST_CREATION_DATE = 'REQUEST_CREATION_DATE',
  COMPENSATION_END_DATE = 'COMPENSATION_END_DATE',
  SAMPLING_STATUS = 'SAMPLING_STATUS',
  CREDIT_EVENT_MOTIF = 'CREDIT_EVENT_MOTIF',
  CREDIT_EVENT_SUB_MOTIF = 'CREDIT_EVENT_SUB_MOTIF',
  REAL_CLIENT_LOAN = "REAL_CLIENT_LOAN",
  LAST_CLAIM_UPDATE_DATE = "LAST_CLAIM_UPDATE_DATE",

  ID_BRP = 'ID_BRP',
  ORGANISATION_REVENUE_REQUESTED = 'ORGANISATION_REVENUE_REQUESTED',
  PAYROLL_REQUESTED = 'PAYROLL_REQUESTED',
  OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED = 'OPERATIONAL_RANGE_AMORTIZATION_PROFILE_REQUESTED',
  OUTSTANDING_PRINCIPAL_AT_REARRANGEMENT_DATE = 'OUTSTANDING_PRINCIPAL_AT_REARRANGEMENT_DATE',
  EFFECTIVE_RESCHEDULING_DATE = 'EFFECTIVE_RESCHEDULING_DATE',
  PAYMENT_DUE_FREQUENCY = 'PAYMENT_DUE_FREQUENCY',
  PAYMENT_DUE_FREQUENCY_REQUESTED = 'PAYMENT_DUE_FREQUENCY_REQUESTED',
  PAYMENT_SCHEDULE_TERMINATION_DATE = 'PAYMENT_SCHEDULE_TERMINATION_DATE',
  SUSPENDED_PAYMENT_DUE_NUMBER = 'SUSPENDED_PAYMENT_DUE_NUMBER',
  INTEREST_RATE_REQUESTED = 'INTEREST_RATE_REQUESTED',
  INNOVATIVE_COMPANY = 'INNOVATIVE_COMPANY',
  CUSTOMER_AGREEMENT_REFERENCE = 'CUSTOMER_AGREEMENT_REFERENCE',
  PRODUCT = 'PRODUCT',
  CREDIT_RELEASE_DATE = 'CREDIT_RELEASE_DATE',
  CREDIT_AMOUNT = 'CREDIT_AMOUNT',
  LOAN_TERM = 'LOAN_TERM',
  DAMPING_MODE = 'DAMPING_MODE',
  COMMISSION_RATE = 'COMMISSION_RATE',
  INTERVENING_INSTITUTION = 'INTERVENING_INSTITUTION',
  SETTLEMENT_AMOUNT = 'SETTLEMENT_AMOUNT',
  CREATED_BY = 'CREATED_BY',
  AMOUNT_TO_KEEP = "AMOUNT_TO_KEEP",
  AMOUNT_FEES_ACCESS = "AMOUNT_FEES_ACCESS",
  DEFINITIVE_COMPENSATION_STATEMENT_DATE = 'DEFINITIVE_COMPENSATION_STATEMENT_DATE',
  GUARANTEE_ACTIVATION_DUE_PAYMENT = 'GUARANTEE_ACTIVATION_DUE_PAYMENT',
  FORFEITED_OUTSTANDING_PRINCIPAL = 'FORFEITED_OUTSTANDING_PRINCIPAL',
  REMAIN_AMOUT_PROVISIONAL_PAYMENT = "REMAIN_AMOUT_PROVISIONAL_PAYMENT",
  AMOUNT_VP_RESTRUCT = "AMOUNT_VP_RESTRUCT",
  AMOUNT_COMPENSATION_VF_FORFEITED = "AMOUNT_COMPENSATION_VF_FORFEITED",
  IS_CTX_WITH_FORFEITED = 'IS_CTX_WITH_FORFEITED',
  INSTALLMENT_PAYMENT_REQUEST = 'INSTALLMENT_PAYMENT_REQUEST',
  REARRANGEMENT_FEES_AMOUNT = 'REARRANGEMENT_FEES_AMOUNT',
  SCHEDULES = 'SCHEDULES',
  CLAIM_TYPE = 'CLAIM_TYPE'
}

export type FieldsCode = FieldsValueCode | FieldsAttachmentCode;
